<template>
  <div class="prices mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Preismatrix</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text class="px-16 py-10">
          <table>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th
                  v-for="(value, key) in matrix[0].values"
                  :key="key"
                  class="text-right"
                >
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(category, matrixIndex) in matrix">
                <tr :key="category.key">
                  <td :colspan="matrix.length + 1">
                    <h4 class="mt-8">{{ category.title }}</h4>
                  </td>
                </tr>
                <tr
                  v-for="(value, hour) in category.values.default"
                  :key="hour + category.title"
                >
                  <td class="text-left">
                    {{ value.name }}
                  </td>
                  <td
                    v-for="(fick, index) in matrix[matrixIndex].values"
                    :key="index"
                    class="text-right"
                  >
                    CHF {{ fick[hour].amount | formatCurrency }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["configs"]),

    categories() {
      return Object.keys(this.configs.pricing_categories)
        .filter(c => c !== "99_99")
        .map(c => ({
          name: this.configs.pricing_categories[c],
          key: c
        }));
    },

    matrix() {
      return Object.keys(this.configs.pricing_matrix)
        .filter(c => c !== "99_99")
        .map(c => ({
          values: this.configs.pricing_matrix[c],
          title: this.configs.pricing_categories[c]
        }));
    }
  }
};
</script>

<style lang="scss">
.prices {
  table {
    width: 100%;
    border-collapse: collapse;

    thead tr th {
      text-transform: uppercase;
      padding: 3px;
      color: #333;
    }
    tbody tr td {
      border-bottom: 1px solid #ddd;
      padding: 3px;
      color: #333;
    }
  }
}
</style>
